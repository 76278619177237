import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  Markdown,
  Pressable,
  ScreenContainer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { Platform, ScrollView, Text, View } from 'react-native';

const PrivacyPolicyScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          dimensions.width
        )}
      >
        <>
          {Platform.OS === 'web' ? null : (
            <View
              style={StyleSheet.applyWidth({ height: 48 }, dimensions.width)}
            />
          )}
        </>
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              flex: 1,
              fontFamily: 'BaiJamjuree_600SemiBold',
              fontSize: 21,
              marginLeft: 48,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Privacy Policy'}
        </Text>
        {/* close */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          activeOpacity={0.3}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={theme.colors['App Theme']}
              name={'AntDesign/closecircleo'}
            />
          </View>
        </Pressable>
      </View>
      <WebView
        allowFileAccessFromFileURLs={false}
        allowUniversalAccessFromFileURLs={false}
        cacheEnabled={true}
        incognito={false}
        javaScriptCanOpenWindowsAutomatically={false}
        mediaPlaybackRequiresUserAction={false}
        startInLoadingState={false}
        {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
        javaScriptEnabled={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        source={{ uri: `${Constants['PRIVACY_POLICY']}` }}
        style={StyleSheet.applyWidth(
          GlobalStyles.WebViewStyles(theme)['Web View'].style,
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(PrivacyPolicyScreen);
