import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoBackendApi from '../apis/XanoBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Markdown,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  ImageBackground,
  Modal,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

const PhotoReadyScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showContentModal, setShowContentModal] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await XanoBackendApi.contentGET(Constants))?.json;
        setGlobalVariableValue({
          key: 'PRIVACY_POLICY',
          value: (result && result[0])?.privacy?.url,
        });
        setGlobalVariableValue({
          key: 'TERMS_CONDITIONS',
          value: (result && result[0])?.terms?.url,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: [
            { minWidth: Breakpoints.Laptop, value: 'center' },
            { minWidth: Breakpoints.Desktop, value: 'center' },
            { minWidth: Breakpoints.Tablet, value: 'center' },
          ],
        },
        dimensions.width
      )}
    >
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            justifyContent: {
              minWidth: Breakpoints.Tablet,
              value: 'space-between',
            },
            maxWidth: [
              { minWidth: Breakpoints.Laptop, value: 1200 },
              { minWidth: Breakpoints.BigScreen, value: 1200 },
              { minWidth: Breakpoints.Desktop, value: 1200 },
            ],
          },
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 1 },
              borderColor: {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Divider'],
              },
              flexDirection: [
                { minWidth: Breakpoints.Tablet, value: 'row' },
                { minWidth: Breakpoints.Mobile, value: 'row' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Tablet, value: 'space-between' },
                { minWidth: Breakpoints.Mobile, value: 'space-between' },
              ],
              padding: [
                { minWidth: Breakpoints.Laptop, value: 20 },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              paddingBottom: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              width: { minWidth: Breakpoints.Laptop, value: '100%' },
            },
            dimensions.width
          )}
        >
          {/* Logo */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: [
                  { minWidth: Breakpoints.Tablet, value: 'row' },
                  { minWidth: Breakpoints.Mobile, value: 'row' },
                ],
              },
              dimensions.width
            )}
          >
            {/* logo */}
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={Images.Chameleon}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 55 },
                      { minWidth: Breakpoints.Laptop, value: 45 },
                    ],
                    width: [
                      { minWidth: Breakpoints.Mobile, value: 55 },
                      { minWidth: Breakpoints.Laptop, value: 45 },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['App Theme'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['App Theme'],
                      },
                    ],
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_600SemiBold',
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: 'BaiJamjuree_600SemiBold',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 25 },
                      { minWidth: Breakpoints.BigScreen, value: 27 },
                      { minWidth: Breakpoints.Mobile, value: 18 },
                    ],
                    marginLeft: { minWidth: Breakpoints.Laptop, value: 6 },
                  }
                ),
                dimensions.width
              )}
            >
              {'PhotoReady App'}
            </Text>
          </View>
          {/* Get App */}
          <View>
            <Button
              onPress={() => {
                try {
                  Linking.openURL('mailto:support@photoreadyapp.com');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['App Theme'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['App Theme'],
                      },
                    ],
                    borderRadius: [
                      { minWidth: Breakpoints.Laptop, value: 100 },
                      { minWidth: Breakpoints.Mobile, value: 100 },
                    ],
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_300Light',
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: 'BaiJamjuree_300Light',
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'BaiJamjuree_400Regular',
                      },
                    ],
                    letterSpacing: { minWidth: Breakpoints.Laptop, value: 0.5 },
                    paddingLeft: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={'Contact us'}
            />
          </View>
        </View>

        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          {/* Hero Section */}
          <ImageBackground
            {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
              .props}
            resizeMode={'repeat'}
            source={Images.Rectangle2}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                  .style,
                {
                  alignItems: [
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                    { minWidth: Breakpoints.Tablet, value: 'row' },
                  ],
                  justifyContent: [
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                    { minWidth: Breakpoints.Tablet, value: 'space-between' },
                  ],
                  padding: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                  paddingTop: 0,
                }
              ),
              dimensions.width
            )}
          >
            {/* Left View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  paddingRight: [
                    { minWidth: Breakpoints.Laptop, value: '12%' },
                    { minWidth: Breakpoints.Tablet, value: '6%' },
                  ],
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* The done-for-you */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 25 },
                        { minWidth: Breakpoints.Mobile, value: 25 },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Laptop, value: 40 },
                        { minWidth: Breakpoints.Mobile, value: 40 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {'The done-for-you'}
              </Text>
              {/* Photo Editing Assistant */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['App Theme'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['App Theme'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 25 },
                        { minWidth: Breakpoints.Mobile, value: 25 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Photo Editing Assistant'}
              </Text>
              {/* Description */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Medium'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Medium'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_400Regular',
                        },
                      ],
                      fontSize: { minWidth: Breakpoints.Laptop, value: 14 },
                      marginTop: [
                        { minWidth: Breakpoints.Laptop, value: 25 },
                        { minWidth: Breakpoints.Mobile, value: 25 },
                      ],
                      textAlign: [
                        { minWidth: Breakpoints.Laptop, value: 'justify' },
                        { minWidth: Breakpoints.Tablet, value: 'justify' },
                        { minWidth: Breakpoints.Mobile, value: 'justify' },
                      ],
                      whiteSpace: [
                        { minWidth: Breakpoints.Laptop, value: 'pre-line' },
                        { minWidth: Breakpoints.Tablet, value: 'pre-line' },
                        { minWidth: Breakpoints.Mobile, value: 'pre-line' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Discover the power of professional photo editing at your fingertips with PhotoReady App. Transform your smartphone into an easy-to-use editing solution for real estate agents, entrepreneurs, and all industry professionals. Enjoy the freedom to capture high quality images anytime, anywhere, eliminating copyright worries and slashing marketing costs. With the PhotoReady App, scheduling conflicts and delays are things of the past, allowing you to seize every photo opportunity instantly. Our world-class editing team will elevate your photos with a professional finish, quickly returning them to you, ready to impress in today’s competitive market. Take back your budget, time, and copyright with PhotoReady App - where YOUR photos meet OUR edits to create magic.'
                }
              </Text>
              {/* App Download Buttons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                    flexDirection: [
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                      { minWidth: Breakpoints.Mobile, value: 'row' },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: 40 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* iOS */}
                <Pressable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          `${Constants['AppStore_Link']}`
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  activeOpacity={0.3}
                >
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={Images.AppStore}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 57 },
                            { minWidth: Breakpoints.Mobile, value: 57 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 160 },
                            { minWidth: Breakpoints.Mobile, value: 160 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
                {/* Android */}
                <Pressable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          `${Constants['PlayStore_Link']}`
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  activeOpacity={0.3}
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: [
                        { minWidth: Breakpoints.Laptop, value: 30 },
                        { minWidth: Breakpoints.Mobile, value: 15 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={Images.PlayStore}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 61 },
                            { minWidth: Breakpoints.Mobile, value: 61 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 170 },
                            { minWidth: Breakpoints.Mobile, value: 170 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
              </View>
            </View>
            {/* Right View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                    { minWidth: Breakpoints.Mobile, value: 'row' },
                  ],
                  justifyContent: 'center',
                  paddingRight: [
                    { minWidth: Breakpoints.Laptop, value: '4%' },
                    { minWidth: Breakpoints.Mobile, value: '4%' },
                  ],
                  paddingTop: 20,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Android */}
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={Images.Silver2}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    {
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 500 },
                        { minWidth: Breakpoints.Mobile, value: 320 },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Laptop, value: 20 },
                        { minWidth: Breakpoints.Mobile, value: 20 },
                      ],
                      right: [
                        { minWidth: Breakpoints.Laptop, value: 85 },
                        { minWidth: Breakpoints.Mobile, value: 40 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Laptop, value: 370 },
                        { minWidth: Breakpoints.Mobile, value: 230 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              />
              {/* iOS */}
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={Images.Silver3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    {
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 500 },
                        { minWidth: Breakpoints.Mobile, value: 300 },
                        { minWidth: Breakpoints.Tablet, value: 340 },
                      ],
                      position: [
                        { minWidth: Breakpoints.Laptop, value: 'absolute' },
                        { minWidth: Breakpoints.Mobile, value: 'absolute' },
                      ],
                      right: [
                        { minWidth: Breakpoints.Laptop, value: 10 },
                        { minWidth: Breakpoints.Mobile, value: 50 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Laptop, value: 230 },
                        { minWidth: Breakpoints.Mobile, value: 140 },
                        { minWidth: Breakpoints.Tablet, value: 140 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </ImageBackground>
          {/* Download App Section */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                ],
                flexDirection: [
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                  { minWidth: Breakpoints.Tablet, value: 'row' },
                ],
                justifyContent: [
                  { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  { minWidth: Breakpoints.Tablet, value: 'space-between' },
                ],
                padding: [
                  { minWidth: Breakpoints.Laptop, value: 20 },
                  { minWidth: Breakpoints.Mobile, value: 20 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Left */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingRight: [
                    { minWidth: Breakpoints.Laptop, value: '12%' },
                    { minWidth: Breakpoints.Tablet, value: '6%' },
                  ],
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Download the */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 21 },
                        { minWidth: Breakpoints.Mobile, value: 21 },
                      ],
                      marginTop: { minWidth: Breakpoints.Laptop, value: 40 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Download the'}
              </Text>
              {/* PhotoReady App Today! */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['App Theme'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['App Theme'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 21 },
                        { minWidth: Breakpoints.Mobile, value: 21 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {'PhotoReady App Today!'}
              </Text>
              {/* Description */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Medium'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Medium'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'BaiJamjuree_400Regular',
                        },
                      ],
                      fontSize: { minWidth: Breakpoints.Laptop, value: 14 },
                      marginTop: [
                        { minWidth: Breakpoints.Laptop, value: 25 },
                        { minWidth: Breakpoints.Mobile, value: 25 },
                      ],
                      textAlign: [
                        { minWidth: Breakpoints.Laptop, value: 'justify' },
                        { minWidth: Breakpoints.Mobile, value: 'justify' },
                      ],
                      whiteSpace: [
                        { minWidth: Breakpoints.Laptop, value: 'pre-line' },
                        { minWidth: Breakpoints.Mobile, value: 'pre-line' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Developed by a licensed real estate broker and owner of a multi-million dollar real estate photography company, the PhotoReady App is your gateway to efficient, cost-effective marketing. The PhotoReady App was created to empower real estate agents, property managers, VRBO/FSBO, and other business owners with a tool that saves them time, cuts costs, and ensures copyright protection. Embrace hassle-free marketing and let our team of experts help elevate your listings, services, and products.\n\nDownload PhotoReady App now and transform how you market in the digital age.'
                }
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                    flexDirection: [
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                      { minWidth: Breakpoints.Mobile, value: 'row' },
                    ],
                    justifyContent: [
                      { minWidth: Breakpoints.Laptop, value: 'space-between' },
                      { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    ],
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: 40 },
                      { minWidth: Breakpoints.Mobile, value: 40 },
                    ],
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Divider'],
                      },
                      borderRightWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 2,
                      },
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_700Bold',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_700Bold',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 13 },
                            { minWidth: Breakpoints.Mobile, value: 13 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'4 Million +'}
                  </Text>
                  {/* value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['App Theme'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['App Theme'],
                            },
                          ],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_500Medium',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_500Medium',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 10 },
                            { minWidth: Breakpoints.Mobile, value: 10 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Photos Edited'}
                  </Text>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Divider'],
                      },
                      borderRightWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 2,
                      },
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_700Bold',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_700Bold',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 13 },
                            { minWidth: Breakpoints.Mobile, value: 13 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'300K +'}
                  </Text>
                  {/* value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['App Theme'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['App Theme'],
                            },
                          ],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_500Medium',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_400Regular',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 10 },
                            { minWidth: Breakpoints.Mobile, value: 10 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Orders Delivered'}
                  </Text>
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      flex: { minWidth: Breakpoints.Laptop, value: 1 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_700Bold',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_700Bold',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 13 },
                            { minWidth: Breakpoints.Mobile, value: 13 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'25K +'}
                  </Text>
                  {/* value */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['App Theme'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['App Theme'],
                            },
                          ],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_500Medium',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'BaiJamjuree_400Regular',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 10 },
                            { minWidth: Breakpoints.Mobile, value: 10 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Customers Served'}
                  </Text>
                </View>
              </View>
            </View>
            {/* Right */}
            <>
              {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      flexDirection: [
                        { minWidth: Breakpoints.Laptop, value: 'row' },
                        { minWidth: Breakpoints.Tablet, value: 'row' },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      width: [
                        { minWidth: Breakpoints.Laptop, value: '50%' },
                        { minWidth: Breakpoints.Tablet, value: '50%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Android */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_500Medium',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'For Android'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Light'],
                            },
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 13 },
                              { minWidth: Breakpoints.Tablet, value: 13 },
                            ],
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 4 },
                              { minWidth: Breakpoints.Tablet, value: 4 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Android 13.2 +'}
                    </Text>
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${Constants['PlayStore_Link']}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      activeOpacity={0.3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['App Theme'],
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors['App Theme'],
                              },
                            ],
                            borderRadius: [
                              { minWidth: Breakpoints.Laptop, value: 100 },
                              { minWidth: Breakpoints.Tablet, value: 100 },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_300Light',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_300Light',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 16 },
                              { minWidth: Breakpoints.Tablet, value: 16 },
                            ],
                            letterSpacing: {
                              minWidth: Breakpoints.Laptop,
                              value: 0.5,
                            },
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 45 },
                              { minWidth: Breakpoints.Tablet, value: 45 },
                            ],
                            paddingLeft: [
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Download App'}
                    />
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={Images.Android}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            height: {
                              minWidth: Breakpoints.BigScreen,
                              value: 150,
                            },
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 45 },
                              { minWidth: Breakpoints.Tablet, value: 45 },
                            ],
                            width: {
                              minWidth: Breakpoints.BigScreen,
                              value: 150,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* iOS */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                        ],
                        marginLeft: [
                          { minWidth: Breakpoints.Laptop, value: 60 },
                          { minWidth: Breakpoints.Tablet, value: 60 },
                        ],
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 50,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_500Medium',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'For iOS'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Light'],
                            },
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 13 },
                              { minWidth: Breakpoints.Tablet, value: 13 },
                            ],
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 4 },
                              { minWidth: Breakpoints.Tablet, value: 4 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'iOS 15.6 +'}
                    </Text>
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${Constants['AppStore_Link']}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      activeOpacity={0.3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['App Theme'],
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors['App Theme'],
                              },
                            ],
                            borderRadius: [
                              { minWidth: Breakpoints.Laptop, value: 100 },
                              { minWidth: Breakpoints.Tablet, value: 100 },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_300Light',
                              },
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'BaiJamjuree_300Light',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 16 },
                              { minWidth: Breakpoints.Tablet, value: 16 },
                            ],
                            letterSpacing: {
                              minWidth: Breakpoints.Laptop,
                              value: 0.5,
                            },
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 45 },
                              { minWidth: Breakpoints.Tablet, value: 45 },
                            ],
                            paddingLeft: [
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Download App'}
                    />
                    <Image
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      resizeMode={'cover'}
                      source={Images.IOS}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            height: {
                              minWidth: Breakpoints.BigScreen,
                              value: 150,
                            },
                            marginTop: [
                              { minWidth: Breakpoints.Laptop, value: 45 },
                              { minWidth: Breakpoints.Tablet, value: 45 },
                            ],
                            width: {
                              minWidth: Breakpoints.BigScreen,
                              value: 150,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </ScrollView>
        {/* Footer */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              borderColor: theme.colors['Divider'],
              borderTopWidth: 1,
              flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'space-between' },
              ],
              minHeight: 50,
              paddingLeft: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingRight: { minWidth: Breakpoints.Tablet, value: 20 },
              paddingTop: 10,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Privacy Policy */}
          <Pressable
            onPress={() => {
              try {
                /* hidden 'Set Variable' action */
                navigation.navigate('PrivacyPolicyScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            activeOpacity={0.3}
            style={StyleSheet.applyWidth(
              { width: { minWidth: Breakpoints.Tablet, value: '33%' } },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  height: 40,
                  justifyContent: 'center',
                  width: { minWidth: Breakpoints.Tablet, value: '100%' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['App Theme'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['App Theme'],
                        },
                      ],
                      fontFamily: 'BaiJamjuree_400Regular',
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Privacy Policy'}
              </Text>
            </View>
          </Pressable>
          {/* Terms and Conditions */}
          <Pressable
            onPress={() => {
              try {
                /* hidden 'Set Variable' action */
                navigation.navigate('TermsAndConditionsScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            activeOpacity={0.3}
            style={StyleSheet.applyWidth(
              { width: { minWidth: Breakpoints.Tablet, value: '33%' } },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  height: 40,
                  justifyContent: 'center',
                  width: { minWidth: Breakpoints.Tablet, value: '100%' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['App Theme'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['App Theme'],
                        },
                      ],
                      fontFamily: 'BaiJamjuree_400Regular',
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Terms and Conditions'}
              </Text>
            </View>
          </Pressable>
          {/* Copyright */}
          <View
            style={StyleSheet.applyWidth(
              {
                height: 70,
                justifyContent: 'center',
                width: { minWidth: Breakpoints.Tablet, value: '33%' },
              },
              dimensions.width
            )}
          >
            {/* copyright */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: 'BaiJamjuree_400Regular',
                    fontSize: 11,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'@ Copyright 2024, All Rights Reserved by'}
            </Text>
            {/* PhotoReady App */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors['App Theme'],
                    fontFamily: 'BaiJamjuree_500Medium',
                    marginTop: 3,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'PhotoReady App'}
            </Text>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PhotoReadyScreen);
