export default {
  Chameleon: require('../assets/images/Chameleon.png'),
  Rectangle2: require('../assets/images/Rectangle2.png'),
  PlayStore: require('../assets/images/PlayStore.png'),
  AppStore: require('../assets/images/AppStore.png'),
  Vector: require('../assets/images/Vector.png'),
  PhotoReadyApp1: require('../assets/images/PhotoReadyApp(1).png'),
  Silver2: require('../assets/images/Silver(2).png'),
  Silver3: require('../assets/images/Silver(3).png'),
  IOS: require('../assets/images/IOS.png'),
  Android: require('../assets/images/Android.png'),
};
